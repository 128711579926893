import React, { useEffect, useState, useRef } from 'react'
import styled, { css, Box } from '@xstyled/styled-components'
import { useContentInProp } from '@/hooks/useContentInProp'
import { breakpoints } from '@xstyled/system'
import gsap from 'gsap'

interface OhpeneerType {
  id: string
  text: string
  name: string
  quote: {
    text: string
    jobTitle: string
  }
  source: string
}

const GridViewOhpeneersBackgroundMobile: React.FC<{
  ohpeneers: OhpeneerType[]
}> = ({ ohpeneers }) => {
  const inProp = useContentInProp()
  const ohpeneersWithImage = ohpeneers.filter((ohpeneer) => !!ohpeneer.source)
  const containerRef = useRef()
  const [randomOhpeneers] = useState(gsap.utils.shuffle(ohpeneersWithImage))
  useEffect(() => {
    if (containerRef) {
      const tl = gsap.timeline()

      tl.fromTo(
        containerRef.current,
        1.5,
        {
          opacity: 0,
        },
        {
          ease: 'sine.inOut',
          opacity: 1,
        },
      )
    }
  }, [containerRef, inProp])

  return (
    inProp && (
      <Container ref={containerRef}>
        <Box row>
          {randomOhpeneers.map(
            (ohpeneer, index) =>
              index < 10 && (
                <Box className="square" col={0.5} key={ohpeneer.id}>
                  <ImageContainer>
                    <img alt={ohpeneer.name} src={ohpeneer.source} />
                  </ImageContainer>
                </Box>
              ),
          )}
        </Box>
      </Container>
    )
  )
}
const ImageContainer = styled.div`
  padding: 4px;
  img {
    width: 100%;
    height: 100%;
  }
`
const Container = styled.div`
  opacity: 0;
  padding: 4px;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.8;
  }
  .square {
    height: 0;
    padding-bottom: 50%;
  }
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 0;
  ${breakpoints({
    lg: css`
      display: none;
    `,
  })}
`

export default GridViewOhpeneersBackgroundMobile
