import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import GridViewOhpeneers from '@/components/GridViewOhpeneers'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useContentInProp } from '@/hooks/useContentInProp'
import GridViewOhpeneersBackgroundMobile from './GridViewOhpeneersBackgroundMobile'

export const query = graphql`
  query {
    allCmsOhpeneer(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          status
          created_on
          name
          function
          image {
            data {
              url
            }
          }
          quote
          featured
          overview
          changemaker_image {
            data {
              url
            }
          }
        }
      }
    }
  }
`

const transformNode = ({ node }) => ({
  inOverview: node.overview,
  inChangemakers: node.featured,
  id: node.slug,
  text: node.text,
  name: node.name,
  source: node?.image?.data?.url,
  quote: {
    text: node.quote,
    jobTitle: node.function,
  },
})

interface OhpeneerOverviewData {
  allCmsOhpeneer: GatsbyTypes.CmsOhpeneerConnection
}

const OhpeneerPage: React.FC<{ data: OhpeneerOverviewData }> = ({ data }) => {
  const ohpeneers = data.allCmsOhpeneer.edges.map(transformNode)

  const { t } = useTranslation()
  const inProp = useContentInProp()
  let setGridDisplayTimeout
  const [gridDisplay, setGridDisplay] = useState(false)

  const content = {
    pretitle: t(
      'dna.ohpeneeroverview_super',
      'fill copy key: dna.ohpeneeroverview_super',
    ),
    title: t(
      'dna.ohpeneeroverview_title',
      'fill copy key: dna.ohpeneeroverview_title',
    ),
    text: t(
      'dna.ohpeneeroverview_body',
      'fill copy key: dna.ohpeneeroverview_body',
    ),

    links: [
      {
        link: t(
          'dna.ohpeneeroverview_cta',
          'fill copy key: dna.ohpeneeroverview_cta',
        ),
        url: '/our-dna/social',
        variant: 'all',
      },
      {
        link: 'View all Ohpeneers',
        url: '/our-dna/all-ohpeneers',
        variant: 'lgDown',
      },
    ],
  }
  const displayGrid = () => {
    setGridDisplay(true)
  }

  useEffect(() => {
    if (inProp) {
      setGridDisplayTimeout = setTimeout(displayGrid, 1000)
    }
    return () => {
      setGridDisplay(false)
      clearTimeout(setGridDisplayTimeout)
    }
  }, [inProp])

  return (
    <>
      <GridViewOhpeneersBackgroundMobile ohpeneers={ohpeneers} />
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box col={0.5}>
                  <Box col={0.8}>
                    <Text {...content} />
                  </Box>
                </Box>
                <Box col={0.5}>
                  {inProp && (
                    <GridViewOhpeneers
                      ohpeneers={ohpeneers}
                      gridReady={gridDisplay}
                    />
                  )}
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default OhpeneerPage
