import React, { useState } from 'react'
import gsap from 'gsap'
import { GridContainer, GridRow, GridCol } from './styled'
import GridBlock from './GridBlock'
import debounce from 'lodash.debounce'
import { navigate } from '@reach/router'

interface OhpeneerType {
  id: string
  text: string
  name: string
  quote: {
    text: string
    jobTitle: string
  }
  source: string
}

interface Props {
  gridReady: boolean
  ohpeneers: OhpeneerType[]
}

/* eslint-disable indent */
const getRandomOrder = (ids) => gsap.utils.shuffle(ids)

const GridViewOhpeneers: React.FC<Props> = ({ ohpeneers, gridReady }) => {
  const ohpeneerIds = ohpeneers
    .filter((ohpeneer) => ohpeneer.inOverview === true && ohpeneer.id)
    .map((ohpeneer) => ohpeneer.id)

  const [ohpeneerOrder] = useState(getRandomOrder(ohpeneerIds))
  const [hoverId, setHoverId] = useState(-1)

  const updateCalloutPosition = (index) => {
    let y = 1 + Math.floor(index / 4)
    y = y === 4 ? 2 : y
  }
  const onHoverIdChange = debounce((id) => {
    setHoverId(id)
    const index = ohpeneerOrder.indexOf(id)
    updateCalloutPosition(index)
  }, 500)

  const onBlur = debounce(() => {
    setHoverId(-1)
  }, 500)

  const onClickOhpeneer = (ohpeneerId) => {
    navigate(`/our-dna/ohpeneer/${ohpeneerId}`)
  }
  return (
    <GridContainer data-url>
      <GridRow row>
        {ohpeneerOrder.slice(0, 4).map((opheneerID, index) => (
          <GridCol
            onClick={() => {
              onClickOhpeneer(opheneerID)
            }}
            data-url
            col={0.25}
            key={`block-1-${opheneerID}`}
          >
            <GridBlock
              data-url
              hoverId={hoverId}
              onHover={onHoverIdChange}
              onBlur={onBlur}
              ohpeneers={ohpeneers}
              ohpeneerId={opheneerID}
              staggerBy={index * 0.1}
              isCalloutRight={index === 3}
              gridReady={gridReady}
            />
          </GridCol>
        ))}
      </GridRow>
      <GridRow row>
        {ohpeneerOrder.slice(4, 8).map((opheneerID, index) => (
          <GridCol
            onClick={() => {
              onClickOhpeneer(opheneerID)
            }}
            data-url
            col={0.25}
            key={`block-2-${opheneerID}`}
          >
            <GridBlock
              data-url
              hoverId={hoverId}
              onHover={onHoverIdChange}
              onBlur={onBlur}
              ohpeneers={ohpeneers}
              ohpeneerId={opheneerID}
              staggerBy={0.4 + index * 0.1}
              isCalloutRight={index === 3}
              gridReady={gridReady}
            />
          </GridCol>
        ))}
      </GridRow>
      <GridRow row>
        {ohpeneerOrder.slice(8, 12).map((opheneerID, index) => (
          <GridCol
            onClick={() => {
              onClickOhpeneer(opheneerID)
            }}
            data-url
            col={0.25}
            key={`block-3-${opheneerID}`}
          >
            <GridBlock
              data-url
              hoverId={hoverId}
              onHover={onHoverIdChange}
              onBlur={onBlur}
              ohpeneers={ohpeneers}
              ohpeneerId={opheneerID}
              staggerBy={0.8 + index * 0.1}
              isCalloutRight={index === 3}
              isCalloutBottom={ohpeneerOrder.length <= 12}
              gridReady={gridReady}
            />
          </GridCol>
        ))}
      </GridRow>
      <GridRow row>
        {ohpeneerOrder.slice(12, 16).map((opheneerID, index) => (
          <GridCol
            onClick={() => {
              onClickOhpeneer(opheneerID)
            }}
            data-url
            col={0.25}
            key={`block-4-${opheneerID}`}
          >
            <GridBlock
              data-url
              hoverId={hoverId}
              onHover={onHoverIdChange}
              onBlur={onBlur}
              ohpeneers={ohpeneers}
              ohpeneerId={opheneerID}
              staggerBy={1.2 + index * 0.1}
              isCalloutRight={index === 3}
              isCalloutBottom
              gridReady={gridReady}
            />
          </GridCol>
        ))}
      </GridRow>
    </GridContainer>
  )
}

export default GridViewOhpeneers
