import React, { useState, useEffect, useRef } from 'react'
import { GridBlockContainer, OhpeneerBackground, ItemContent } from './styled'
import TextColumn from './TextColumn'
import gsap from 'gsap'

/* eslint-disable indent */

const GridBlock: React.FC<{
  ohpeneerId: string
  hoverId: string
  onHover: (id) => void
  onBlur: (id) => void
  staggerBy: number
  gridReady: boolean
}> = ({
  ohpeneers,
  ohpeneerId,
  onHover,
  onBlur,
  isCalloutRight = false,
  isCalloutBottom = false,
  hoverId,
  staggerBy = 0,
  gridReady,
}) => {
  const [selectedOhpeneer, setSelectedOhpeneer] = useState(null)
  const [fadeOutOhpeneer, setFadeOutOhpeneer] = useState(null)
  const [newOhpeneer, setNewOhpeneer] = useState(null)
  const timeLineRef = useRef(null)
  const imageRef = useRef(null)
  const fadeOutRef = useRef(null)

  const getOhpeneer = (): OhpeneerType =>
    ohpeneers.find((ohpeneer) => ohpeneer.id === ohpeneerId)
  const [isAnimating, setIsAnimating] = useState(false)
  const ohpeneer = getOhpeneer()

  useEffect(() => {
    setSelectedOhpeneer(newOhpeneer)
    const createTLIn = () => {
      const tl = gsap.timeline({
        repeat: 0,
        delay: staggerBy,
        onComplete: () => {
          // tl.set(fadeOutRef.current, { opacity: 0 })
          // tl.set(imageRef.current, { opacity: 1 })
        },
        timeScale: 1,
        paused: true,
      })
      // tl.set(imageRef.current, { y: '100%' })
      tl.fromTo(
        imageRef.current,
        1,
        {
          y: '100%',
        },
        { ease: 'circ.out', y: '0%' },
        0,
      )
      return tl
    }
    let tl = null

    tl = createTLIn()

    timeLineRef.current = tl

    if ((imageRef.current && fadeOutRef.current, gridReady)) {
      tl.play()
    }
  }, [fadeOutOhpeneer, gridReady])

  useEffect(() => {
    if (ohpeneerId) {
      const newSelectedOhpeneer = getOhpeneer()
      setNewOhpeneer(newSelectedOhpeneer)
      const didOpheneerChange =
        selectedOhpeneer && newSelectedOhpeneer?.id !== selectedOhpeneer.id
      if (didOpheneerChange) {
        setFadeOutOhpeneer(selectedOhpeneer)
      }
      const isInitial = !selectedOhpeneer && newSelectedOhpeneer
      if (isInitial) {
        setSelectedOhpeneer(newSelectedOhpeneer)
      }
    }
  }, [ohpeneerId])

  const onHandleMouseOver = () => {
    onHover(ohpeneer?.id)
  }
  const onHandleMouseOut = () => {
    onBlur(ohpeneer?.id)
  }
  const isBlur = hoverId === -1
  const isActive = hoverId === selectedOhpeneer?.id
  return (
    <GridBlockContainer
      data-url
      onMouseOver={() => onHandleMouseOver()}
      onMouseOut={() => onHandleMouseOut()}
    >
      <ItemContent data-url>
        <OhpeneerBackground data-url active={isActive || isBlur}>
          <img
            ref={imageRef}
            src={`${selectedOhpeneer?.source}`}
            alt={selectedOhpeneer?.id}
          />
          <img
            ref={fadeOutRef}
            className="next"
            src={`${fadeOutOhpeneer?.source}`}
            alt={fadeOutOhpeneer?.id}
          />
        </OhpeneerBackground>
      </ItemContent>
      {(isActive || isAnimating) && (
        <TextColumn
          data-url
          isActive={isActive}
          isCalloutRight={isCalloutRight}
          isCalloutBottom={isCalloutBottom}
          ohpeneer={selectedOhpeneer}
          onSetAnimating={(newState) => setIsAnimating(newState)}
          calloutPosition={{ x: 0, y: 0 }}
        />
      )}
    </GridBlockContainer>
  )
}

export default GridBlock
