import React, { useRef, useState, useEffect } from 'react'
import gsap from 'gsap'
import {
  TextBackground,
  Callout,
  Title,
  Paragraph,
  PanelBackground,
} from './styled'
import { SplitText } from 'gsap/dist/SplitText'

interface Props {
  variant: string
  color: '#000' | '#fff'
}

/* eslint-disable indent */

const TextColumn: React.FC<{
  ohpeneer
  isActive: boolean
  isCalloutRight: boolean
  isCalloutBottom: boolean
  onSetAnimating: (val) => void
}> = ({
  ohpeneer,
  isActive,
  isCalloutRight,
  isCalloutBottom,
  onSetAnimating,
}) => {
  const titleRef = useRef(null)
  const paragraphRef = useRef(null)
  const containerRef = useRef(null)
  const panelRef = useRef(null)
  let timeoutHandle = null
  const [newActiveState, setNewActiveState] = useState(false)
  const stRef = useRef(null)
  const stpRef = useRef(null)
  const tl = useRef(null)

  useEffect(() => {
    tl.current = gsap.timeline({
      paused: true,
      onComplete: () => onSetAnimating(isActive),
    })
    stRef.current = new SplitText(titleRef.current, {
      linesClass: 'line',
      type: 'lines',
    })
    stpRef.current = new SplitText(paragraphRef.current, {
      linesClass: 'line',
      type: 'lines',
    })

    const charsOfLinesTitle = stRef.current.lines.map(
      (line) =>
        new SplitText(line, {
          charsClass: 'char',
          type: 'chars',
        }).chars,
    )
    const charsOfLinesParagraph = stpRef.current.lines.map(
      (line) =>
        new SplitText(line, {
          charsClass: 'char',
          type: 'chars',
        }).chars,
    )
    charsOfLinesTitle.forEach((chars) => {
      tl.current.fromTo(
        chars,
        0.5,
        {
          y: 115,
        },
        {
          force3D: true,
          ease: 'circ.out',
          stagger: 0.02,
          y: 0,
        },
        0,
      )
    })
    charsOfLinesParagraph.forEach((chars) => {
      tl.current.fromTo(
        chars,
        0.5,
        {
          y: 115,
        },
        {
          force3D: true,
          ease: 'circ.out',
          stagger: 0,
          y: 0,
        },
        0.1,
      )
    })

    tl.current.fromTo(
      panelRef.current,
      0.5,
      { y: '100%' },
      { y: '0%', ease: 'circ.inOut' },
      0,
    )

    tl.current.fromTo(
      containerRef.current,
      0.5,
      { autoAlpha: 0 },
      { autoAlpha: 1, ease: 'circ.inOut' },
      0,
    )
  }, [ohpeneer])

  const onSetNewActiveState = () => {
    setNewActiveState(isActive)
    if (isActive) {
      onSetAnimating(isActive)
    }
  }

  /* eslint-disable no-unused-expressions */
  useEffect(() => {
    if (newActiveState) {
      // @ts-ignore
      tl.current?.play()
    } else {
      // @ts-ignore
      tl.current?.reverse()
    }
    return () => {
      // @ts-ignore
      tl.current?.kill()
    }
  }, [newActiveState])

  /* eslint-disable no-unused-expressions */
  useEffect(() => {
    timeoutHandle = setTimeout(onSetNewActiveState, 100)
    return () => {
      // @ts-ignore
      clearTimeout(timeoutHandle)
    }
  }, [isActive])

  return (
    <Callout
      ref={containerRef}
      right={isCalloutRight ? 0 : null}
      bottom={isCalloutBottom ? '100%' : null}
    >
      <PanelBackground ref={panelRef} />
      <TextBackground>
        <Title ref={titleRef}>{ohpeneer?.name}</Title>
        <Paragraph ref={paragraphRef}>{ohpeneer?.quote.jobTitle}</Paragraph>
      </TextBackground>
    </Callout>
  )
}

export default TextColumn
